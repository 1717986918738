:root {
  --v1-energetic-blue: #00bdf0;
  --v1-slate: #303539;
  --v1-energetic-green: #41da44;
  --v1-energetic-red: #e00909;
  --v1-strong-blue: #31708f;
  --bs-info: #5bc0de;
  --bs-info-bg: #d9edf7;
}

html, body {
  height: 100%;
  margin: 0 auto;
}

body {
  background-color: var(--color-primary-100);
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-ms-clear {
  display: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .hint-text {
    width: 60%;
  }
}

.form-control::placeholder {
  color: var(--color-primary-500);
}

label.checkbox + label.checkbox.indent {
  padding-left: 40px;
}

input[type="search"] {
  padding-right: 17px;
}

.nested-form-group {
  margin: 0 -2.5px;
}

.nested-form-group > * {
  padding: 0 2.5px 5px;
}

.form-control.has-visited.ng-invalid, .with-feedback.ng-invalid .ui-select-toggle.ng-invalid, .form-control.submitted.ng-invalid, .with-feedback.ng-invalid .ui-select-toggle.submitted, .form-control.feedback-errored {
  border-color: var(--color-danger-300);
  box-shadow: inset 0 1px 1px #00000013;
}

.input-group > .feedback-wrapper > .form-control.with-addon-right {
  border-radius: 4px 0 0 4px;
}

.label-small {
  font-size: 50%;
}

.checkbox .label-required:after {
  display: none;
}

.form-control-feedback {
  z-index: 4;
}

.form-horizontal .input-group.has-feedback .form-control-feedback, .form-horizontal .input-group .has-feedback .form-control-feedback {
  right: 0;
}

.form-control.with-tooltip:disabled {
  pointer-events: none;
}

.disabled-wrapper {
  cursor: not-allowed;
}

.inactive .form-group p.form-control-static, .form-group.disabled label, .form-group.disabled .form-control-static span {
  opacity: .5;
}

.input-wrapper {
  position: relative;
}

.input-wrapper__icon--clickable {
  cursor: pointer;
}

.input-wrapper__icon > svg {
  fill: currentColor;
  width: .75rem;
  height: .75rem;
  position: absolute;
  top: .75rem;
  right: .75rem;
}

hr {
  margin-left: 0;
  margin-right: 0;
}

hr.double {
  border-bottom-width: 1px;
  border-color: var(--color-primary-150);
  padding-bottom: 5px;
}

hr.thin {
  margin-top: 10px;
  margin-bottom: 10px;
}

hr.minimal {
  margin-top: 0;
  margin-bottom: 0;
}

hr.separator {
  border-bottom-width: 1px;
  border-color: var(--color-primary-150);
  border-top: 2px solid #edeeed;
}

.divider {
  border-top-width: 1px;
  border-color: var(--color-primary-150);
}

.total-divider {
  border-top-width: 1px;
  border-color: var(--color-primary-150);
  margin-top: 5px;
  padding-top: 5px;
}

#spa-document {
  min-height: calc(100vh - var(--total-header-height));
}

.spaced-out {
  margin: 0 10px;
}

.vertical-centered > thead > tr > td, .vertical-centered > tbody > tr > td {
  vertical-align: middle;
}

.pre-wrap {
  white-space: pre-wrap;
}

.nowrap {
  white-space: nowrap;
}

.right {
  float: right;
}

.pt-05 {
  padding-top: .5em;
}

.pl-05 {
  padding-left: .5em;
}

.mb-025 {
  margin-bottom: .25em;
}

.mb-05 {
  margin-bottom: .5em;
}

.ml-05 {
  margin-left: .5em;
}

.ml-50 {
  margin-left: 5em;
}

.mr-025 {
  margin-right: .25em;
}

.mr-05 {
  margin-right: .5em;
}

.mt-0 {
  margin-top: 0;
}

.mt-075 {
  margin-top: .75em;
}

.mt-05 {
  margin-top: .5em;
}

.mb-075 {
  margin-bottom: .75em;
}

.ml-30 {
  margin-left: 7.5em;
}

.mx-05 {
  margin-left: .5em;
  margin-right: .5em;
}

.nmt-05 {
  margin-top: -.5em;
}

.nmb-1 {
  margin-bottom: -1px;
}

.nmb-12 {
  margin-bottom: -12px;
}

.nmx-1 {
  margin-left: -15px;
  margin-right: -15px;
}

.wrapper {
  flex-direction: column;
  flex: 100%;
  display: flex;
}

.horizontal-wrapper {
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  display: flex;
}

.vertical-wrapper {
  height: 100%;
  margin-top: calc(var(--total-header-height)  + var(--mobile-nav-height));
  flex-direction: column;
  flex: 1;
  display: flex;
}

@media (width >= 768px) {
  .vertical-wrapper.with-full-navigation {
    max-width: calc(100% - 60px);
    margin-left: 60px;
  }

  .vertical-wrapper.no-sidebar {
    max-width: 100%;
    margin-left: 0;
  }

  .vertical-wrapper .container {
    width: 100%;
  }
}

.content {
  flex: 1;
  padding: 30px;
}

div.pull-right p {
  text-align: right;
}

.membership-savings {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #5db85b;
  border-radius: .3em;
  margin-left: 10em;
  padding: .2em .6em .3em;
  font-weight: 500;
  line-height: 1;
  display: inline;
}

a {
  color: var(--color-primary-300);
}

a:hover {
  text-decoration: none;
}

a.disabled {
  pointer-events: none;
  cursor: not-allowed;
  filter: opacity(.65);
  opacity: .65;
}

a[disabled] {
  pointer-events: none;
}

a.label:hover {
  text-decoration: none;
}

section {
  padding-top: .75em;
}

section > h5 {
  margin: 0;
}

.table > tbody > tr > td.no-top-border, .table > tbody > tr.no-top-border > td, .table > tbody.no-top-border {
  border-top: none;
}

table.table thead th {
  white-space: nowrap;
}

table.table tbody tr.read {
  background-color: var(--color-primary-100);
}

.tr-line-1 td {
  padding-bottom: 0 !important;
}

.tr-line-2 td {
  border-top: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 12px !important;
}

.border-light {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
}

.table thead .checkbox {
  min-height: unset;
  padding-top: 0;
}

@keyframes wrench {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(18deg);
  }
}

@keyframes flip {
  0% {
    transform: rotateY(0);
  }

  50% {
    transform: rotateY(180deg);
  }

  to {
    transform: rotateY(0);
  }
}

@keyframes beat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.15);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.1);
  }

  70% {
    transform: scale(1);
  }
}

@keyframes loading {
  from {
    z-index: 100;
    width: 0;
    left: 50%;
  }

  33.3333% {
    z-index: 10;
    width: 100%;
    left: 0;
  }

  to {
    width: 100%;
    left: 0;
  }
}

.animate.ng-enter, .animate.ng-leave {
  transition: .4 cubic-bezier(.42, 0, .58, 1) all;
  display: block;
  position: relative;
}

.animate.ng-enter.animate.ng-enter-active, .animate.ng-leave {
  opacity: 1;
  height: 30px;
  bottom: 0;
}

.animate.ng-leave.animate.ng-leave-active, .animate.ng-enter {
  opacity: 0;
  height: 0;
  bottom: -50px;
}

.appear.ng-show, .appear.ng-hide-add, .appear.ng-hide-remove, .appear.ng-leave, .appear.ng-enter.ng-enter-active {
  opacity: 1;
  transition: all .3s ease-in-out;
}

.appear.ng-hide-remove, .appear.ng-hide-add {
  display: block !important;
}

.appear.ng-hide, .appear.ng-show-add, .appear.ng-show-remove, .appear.ng-enter, .appear.ng-leave.ng-leave-active {
  transition: all .3 ease-in-out;
  opacity: 0;
}

.disappear-then-appear.ng-show, .disappear-then-appear.ng-hide-add, .disappear-then-appear.ng-hide-remove, .disappear-then-appear.ng-leave, .disappear-then-appear.ng-enter.ng-enter-active {
  transition: all .3 ease-in-out;
  opacity: 1;
}

.disappear-then-appear.ng-hide, .disappear-then-appear.ng-show-remove, .disappear-then-appear.ng-enter, .disappear-then-appear.ng-leave.ng-leave-active {
  transition: all .3 ease-in-out;
  opacity: 0;
}

.disappear-then-appear.ng-show-add, .disappear-then-appear.ng-hide-remove, .disappear-then-appear.ng-enter.ng-enter-active {
  transition-delay: .15s;
}

.disappear-then-appear.ng-hide-remove, .disappear-then-appear.ng-hide-add {
  position: absolute;
  display: block !important;
}

.slide-down {
  line-height: 100%;
  transition: all .3s ease-in-out;
}

.slide-down.ng-hide-remove, .slide-down.ng-hide-add {
  display: block !important;
}

.slide-down.ng-hide {
  height: 0;
  line-height: 0;
}

.no-animate {
  transition: none !important;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-group .active {
  background-color: var(--color-primary-300);
  color: #fff;
}

.btn-group-justified > .btn {
  width: 100%;
}

.btn-quickbooks {
  border: none;
  padding: 0;
}

.btn-quickbooks > img {
  width: 200px;
}

.btn-quickbooks:disabled > img {
  filter: grayscale();
}

.charge-grid {
  grid-template-columns: max-content max-content max-content auto;
  display: grid;
}

.grid-two {
  grid-template-columns: max-content max-content;
  padding-right: 0;
  display: grid;
}

.grid-two input {
  width: 100px !important;
}

.grid-item {
  padding-right: 20px;
}

.grid-item select {
  width: -webkit-fill-available !important;
}

.ps-mode-modal .ps-checkout-inner {
  max-width: 100% !important;
}

.clickable {
  cursor: pointer;
  color: var(--color-primary-300);
}

.clickable.clickable-default {
  color: inherit;
}

.clickable:hover, .clickable.selectable.selected, .clickable.selectable.selected:hover {
  background-color: #f8f8f8;
}

.clickable.selectable:hover {
  background-color: inherit;
}

.clickable-badge {
  background-color: var(--color-primary-100);
  border-radius: 20px;
  padding: .25rem .75rem;
  font-size: .75rem;
}

.dropdown-menu.datepicker {
  min-width: 289px;
}

.dropdown-menu.typeahead .charge-price {
  float: right;
  margin-right: -15px;
}

.dropdown-menu.typeahead .charge-label {
  padding-right: 75px;
  overflow: hidden;
}

.dropdown-menu.typeahead .charge-description {
  color: var(--color-primary-500);
  overflow: hidden;
}

.dropdown-menu.typeahead .charge-uom {
  float: right;
  margin-right: -15px;
  font-size: 11px;
}

.dropdown-menu.typeahead .active .charge-description {
  color: var(--color-primary-200);
}

.dropdown-toggle__text {
  vertical-align: middle;
  max-width: 75%;
  display: inline-block;
}

.open > .dropdown-menu {
  max-height: 600px;
  overflow-y: auto;
}

.dropdown-menu.scrollable {
  max-height: 400px;
  overflow-y: auto;
}

resource-filter .dropdown-menu {
  z-index: 999;
}

resource-filter .dropdown {
  height: 39px;
}

dropdown-filter .dropdown-filter {
  height: 39px;
  display: block;
}

.caret.dropdown {
  height: 10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  right: 10px;
}

.dropdown > .button--default {
  border-color: var(--color-primary-200);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  color: #000;
}

.dropdown:not(.simpler-dropdown) > .button--xs {
  padding-right: .75rem;
}

.hint-label {
  text-transform: uppercase;
  color: var(--color-primary-500);
  text-align: right;
  padding-top: 10px;
  font-size: 12px;
  font-weight: normal;
}

.hint-label.p-0 {
  padding-top: 0;
}

.hint-label-beside-name {
  font-size: 11px;
}

.form-group label.control-label {
  text-transform: uppercase;
  height: 34px;
  color: var(--color-primary-500);
  padding-top: 8px;
  font-size: 12px;
  font-weight: normal;
}

.form-group label.control-label.p-0 {
  padding-top: 0;
}

.hint-label-left {
  text-align: left;
}

th {
  text-transform: uppercase;
  color: var(--color-primary-500);
  text-align: left;
  padding-top: 10px;
  font-size: 12px;
  font-weight: normal;
}

.right-side-icon {
  border-left-width: 1px;
  border-color: var(--color-primary-100);
  padding: 8px;
}

.active-icon {
  color: var(--color-success-400);
}

.icon-xs {
  font-size: .75rem;
}

.icon-sm {
  font-size: 1rem;
}

.icon-md {
  font-size: 1.25rem;
}

.icon-lg {
  font-size: 2rem;
}

.icon-list li {
  padding: 0 0 5px 20px;
  display: block;
  position: relative;
}

.icon-list li:before {
  font-family: Glyphicons Halflings;
  font-size: 80%;
  position: absolute;
  top: 3px;
  left: 0;
}

.list-group-item.highlight {
  background-color: var(--color-primary-150);
  background-color: var(--bs-info-bg);
  color: var(--v1-strong-blue);
}

.list-group-item .list-group-item.highlight {
  background-color: var(--bs-info-bg);
  background-color: var(--color-primary-150);
  color: #31708f;
}

.list-group-item .list-group-item.pending {
  border-style: dashed;
}

.list-group.background-success .list-group-item {
  background-color: var(--color-success-100);
}

.list-group.background-error .list-group-item {
  background-color: var(--color-danger-100);
}

.list-group.background-warning .list-group-item {
  background-color: var(--color-warning-100);
}

.load-bar {
  background-color: var(--color-primary-300);
  width: 100%;
  height: 3px;
  margin: 20px 0;
  position: relative;
}

.bar {
  content: "";
  text-align: center;
  width: 0;
  height: 100%;
  display: inline;
  position: absolute;
  left: 50%;
}

.bar:first-child {
  background-color: var(--v1-energetic-blue);
  animation: 3s linear infinite loading;
}

.bar:nth-child(2) {
  background-color: var(--v1-slate);
  animation: 3s linear 1s infinite loading;
}

.bar:nth-child(3) {
  background-color: var(--color-primary-300);
  animation: 3s linear 2s infinite loading;
}

.loading-disabled {
  opacity: .5;
}

.hint-heart {
  transform-style: preserve-3d;
  background-image: url("https://cdn2.hubspot.net/hubfs/2562809/production-app/web/logo-mark.svg");
  background-repeat: no-repeat;
  transition: all 2s linear;
  animation: 2s linear 28s flip;
  display: inline-block;
  position: relative;
  transform: rotateY(0);
}

.hint-heart.beat {
  animation: 1s linear infinite beat;
}

.panel-footer .pagination {
  margin-top: 0;
  margin-bottom: 0;
}

.panel-footer .btn-group > .dropdown-toggle {
  border-left-color: var(--v1-slate);
}

.panel-heading a:hover {
  text-decoration: none;
}

.panel-vertical-align {
  align-items: center;
  display: flex;
}

.box-container {
  flex-wrap: wrap;
  margin: 0 -8px 15px;
  display: flex;
}

.box {
  flex: 1 0 200px;
  margin: 8px;
}

.box .panel-title {
  display: inline-block;
}

.box .panel {
  height: 100%;
}

.box-big {
  flex-grow: 2;
}

.box-section {
  flex-grow: 1;
  flex-basis: 0;
}

.box-section:last-child {
  border-left: 1px solid #fbeed5;
  padding-left: 15px;
}

.practice-pulse-panel .panel-title {
  display: inline-block;
}

.practice-pulse-panel h3 {
  margin-right: 10px;
}

.blue-panel {
  border-color: var(--color-primary-150);
}

.blue-panel .panel-heading {
  border-color: var(--color-primary-150);
  background-color: var(--color-primary-100);
}

.green-panel {
  border-color: var(--color-success-200);
}

.green-panel .panel-heading {
  border-color: var(--color-success-200);
  background-color: var(--color-success-100);
}

.yellow-panel {
  border-color: var(--color-warning-200);
}

.yellow-panel .panel-heading {
  border-color: var(--color-warning-200);
  background-color: var(--color-warning-100);
}

.red-panel {
  border-color: var(--color-danger-200);
}

.red-panel .panel-heading {
  border-color: var(--color-danger-200);
  background-color: var(--color-danger-100);
}

.light-grey-panel {
  border-color: var(--color-primary-100);
}

.light-grey-panel .panel-heading {
  border-color: var(--color-primary-100);
  background-color: var(--color-primary-150);
}

.box-main-number {
  font-size: 30px;
}

.box-percentage {
  margin-top: 20px;
}

.box-percentage.positive {
  color: var(--color-success-400);
}

.box-percentage.negative {
  color: #e00909;
}

.action-plan-row {
  border-bottom-width: 1px;
  border-color: var(--color-primary-100);
  align-items: center;
  padding: 15px;
  display: flex;
}

.action-plan-row.green .action-icon, .action-plan-row.green .action-title {
  color: var(--color-success-400);
}

.action-plan-row.blue .action-icon, .action-plan-row.blue .action-title {
  color: var(--color-primary-300);
}

.action-plan-row.red .action-icon, .action-plan-row.red .action-title {
  color: #e00909;
}

.action-plan-row.gray .action-icon, .action-plan-row.gray .action-title {
  color: var(--color-primary-500);
}

.action-plan-row:last-child {
  border-bottom: 0;
}

.action-icon {
  margin-right: 20px;
}

.action-title {
  margin-bottom: 5px;
  font-size: 12px;
}

.action-button {
  margin-left: auto;
}

.action-button .btn {
  border-radius: 8px;
  padding: 3px 10px;
  font-size: 12px;
}

tags-input .tags {
  border-width: 1px;
  border-color: var(--color-primary-200);
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  width: 100%;
  padding: 3px 12px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: inset 0 1px 1px #00000013;
}

tags-input .tags .tag-item {
  color: #fff;
  background: #428bca;
  border: 1px solid #428bca;
  padding: 0 5px 0 10px;
}

tags-input .tags .tag-item.selected {
  color: #000;
  border-width: 1px;
  border-color: var(--color-primary-100);
  background-color: var(--color-primary-100);
}

tags-input .tags .tag-item.selected .remove-button {
  color: #000;
}

tags-input .tags .tag-item .remove-button {
  color: #fff;
}

.tile-group {
  margin: 15px 0;
}

.tile-item-title {
  font-weight: 500;
}

.tile-item-big-number {
  font-size: 25px;
}

.tile-item:not(:last-child) {
  border-right: 1px solid #d8d8d8;
}

.tile-item {
  padding: 0 10px;
}

.tile-item .text-danger {
  color: var(--v1-energetic-red);
}

.tile-item .text-success {
  color: var(--v1-energetic-green);
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.text-gray {
  color: var(--color-primary-500);
}

.text-dark-gray {
  color: #5c5c5c;
}

.text-warning {
  color: var(--color-warning-400);
}

.text-error {
  color: var(--color-danger-300);
}

.text-success {
  color: var(--color-success-400);
}

.text-current {
  color: currentColor;
}

.text-black {
  color: #000;
}

.text-strike, .text-strike label {
  text-decoration: line-through;
}

.text-friendly, .text-friendly.clickable {
  color: var(--color-primary-300);
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.long-text {
  overflow-wrap: break-word;
  max-width: 180px;
  overflow: hidden;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.display-3 {
  font-size: 22px;
  font-weight: 500;
}

.display-4 {
  font-size: 18px;
  font-weight: 500;
}

.display-5 {
  font-size: 16px;
  font-weight: 500;
}

.big-size {
  font-size: 32px;
}

.small-size {
  font-size: 11px;
}

span.editable, strong.editable {
  cursor: pointer;
  border-bottom: 1px dashed;
}

span.editable:hover, strong.editable:hover {
  color: var(--bs-info);
  border-bottom: none;
}

textarea {
  resize: vertical;
  min-height: 100px;
}

.title-with-button {
  line-height: 34px;
}

.title-separator {
  background-color: var(--color-primary-150);
  color: #333;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  margin: 30px -15px;
  padding: 15px;
  font-size: 16px;
}

.api-key, .authorization-code {
  font-family: monospace;
}

.wrap-word {
  word-break: break-word;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nav-text {
  vertical-align: top;
  width: 80px;
  display: inline-block;
}

.created-by {
  color: gray;
  font-size: .8em;
  display: block;
}

.spinner {
  border: 6px solid #00aeef26;
  border-top-color: #00aeefcc;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin: 0 auto;
  animation: .6s linear infinite rotation;
  display: inline-block;
  position: relative;
}

.spinner-xs {
  width: 15px;
  height: 15px;
}

.spinner-sm {
  width: 20px;
  height: 20px;
}

.spinner-lg {
  width: 100px;
  height: 100px;
}

.spin {
  animation: .75s linear infinite rotation;
}

.debug-info {
  color: #a9a9a9;
  text-align: center;
  z-index: 102;
  background-color: #000;
  font-size: 10px;
}

footer {
  border-style: solid;
  border-width: 1px 0 0;
  border-color: var(--color-primary-150);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  flex: 0 0 60px;
  height: 60px;
}

footer > .container.content {
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
}

footer img {
  height: 1rem;
  margin-top: -4px;
  margin-right: .25rem;
}

footer .flex-row {
  height: 100%;
}

footer p {
  color: var(--color-primary-500);
  margin: 0;
}

footer a {
  color: var(--color-primary-500);
  text-decoration-line: underline;
}

.modal-dialog.large {
  width: 65%;
}

.modal-dialog.x-large {
  width: 75%;
}

.modal-header .btn.pull-right, .report-filter-text {
  margin-right: 10px;
}

.modal-backdrop.am-fade {
  opacity: .5;
  transition: opacity .15s linear;
}

.modal-backdrop.am-fade.ng-enter {
  opacity: 0;
}

.modal-backdrop.am-fade.ng-enter.ng-enter-active, .modal-backdrop.am-fade.ng-leave {
  opacity: .5;
}

.modal-backdrop.am-fade.ng-leave.ng-leave-active {
  opacity: 0;
}

.modal .control-label {
  font-size: 14px;
  line-height: 20px;
}

.modal .help-block, .form-control-static {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
}

.modal input, select {
  font-weight: 400;
}

.modal-embed-text {
  max-height: 30vh;
  overflow: auto;
}

.embed-responsive-full {
  padding-bottom: 100%;
}

.processing-download-modal .modal-body {
  display: flex;
}

.processing-download-modal .modal-body p {
  margin-top: 10px;
}

.modal-radio-label {
  margin: 0 1.5em .5em;
}

.modal-faq {
  border-top-width: 1px;
  border-color: var(--color-primary-100);
  padding: 1em 1em 1.5em;
}

.modal-faq__title {
  color: var(--color-primary-300);
}

.modal-faq__title span {
  padding-right: .3em;
}

.modal-faq__title:not(:first-child) {
  border-top-width: 1px;
  border-color: var(--color-primary-100);
  margin-top: 1em;
  padding-top: 1em;
}

.modal-faq__content {
  padding-top: .6em;
  padding-left: 1.8em;
}

.modal__content-centered {
  flex-direction: column;
  align-items: center;
  padding: 2em;
  display: flex;
}

.modal__content-centered > :last-child {
  padding-top: 1em;
}

.navbar {
  background-color: #fff;
  flex: 0 0 50px;
  margin-bottom: 0;
}

.navbar-icon {
  float: left;
  padding: 15px 0;
}

.navbar-header {
  position: relative;
}

.navbar-header .icon {
  height: 45px;
  margin-top: -15px;
  margin-bottom: -15px;
}

.navbar-header .soft-version-notification {
  position: absolute;
  top: 14px;
  left: -10px;
}

.navbar-header .soft-version-notification__icon {
  font-size: 12px;
  animation: 1s infinite wrench;
}

.navbar-header .soft-version-notification .btn {
  padding: 2px 6px;
}

.navbar-header .soft-version-notification .tooltip {
  width: 200px;
}

.navbar-brand {
  color: var(--v1-slate);
  vertical-align: bottom;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 22px;
  line-height: 24px;
}

.navbar-brand img {
  vertical-align: baseline;
  margin-top: -5px;
  display: inline;
}

.navbar-brand .hint-heart {
  width: 32px;
  height: 32px;
  margin-top: -5px;
  margin-right: 5px;
}

.navbar-brand .hint-text {
  height: 32px;
}

.navbar .nav li > a:hover {
  color: var(--color-primary-300);
}

.navbar .nav li.active > a, .navbar .nav li.active > a:hover, .navbar .nav li.active > a:focus {
  background-color: inherit;
  color: var(--color-primary-300);
}

.nav-panel > .nav-tabs {
  border-bottom: 0;
}

.nav-panel > .nav-tabs li.semi-active > a {
  border-width: 1px;
  border-color: var(--color-primary-150);
  background: #fff;
  border-bottom-color: #0000;
}

.nav-panel.nav-panel-header > .nav-tabs > li.active > a, .nav-panel.nav-panel-header > .nav-tabs > li.active > a:focus, .nav-panel.nav-panel-header > .nav-tabs > li.active > a:hover, .nav-panel.nav-panel-header > .nav-tabs > li.active > .dropdown > .btn, .nav-panel.nav-panel-header > .nav-tabs > li.active > .dropdown > .btn:focus, .nav-panel.nav-panel-header > .nav-tabs > li.active > .dropdown > .btn:hover {
  background-color: var(--color-primary-50);
}

.nav-panel.nav-panel-header-white > .nav-tabs > li.active > a, .nav-panel.nav-panel-header-white > .nav-tabs > li.active > a:focus, .nav-panel.nav-panel-header-white > .nav-tabs > li.active > a:hover, .nav-panel.nav-panel-header-white > .nav-tabs > li.active > .dropdown > .btn, .nav-panel.nav-panel-header-white > .nav-tabs > li.active > .dropdown > .btn:focus, .nav-panel.nav-panel-header-white > .nav-tabs > li.active > .dropdown > .btn:hover {
  background-color: #fff;
}

.nav > li > .dropdown > .dropdown-toggle {
  padding: 10px 15px;
  display: block;
  position: relative;
}

.nav-tabs > li.active > .dropdown > .btn, .nav-tabs > li.active > .dropdown > .btn:focus, .nav-tabs > li.active > .dropdown > .btn:hover {
  border: 1px solid;
  border-color: var(--color-primary-150) var(--color-primary-150) transparent;
  color: #555;
  cursor: default;
  background-color: #fff;
}

.nav-tabs > li > .dropdown > .btn {
  border: 1px solid #0000;
  border-radius: 4px 4px 0 0;
  margin-right: 2px;
  line-height: 1.42857;
}

.navbar-right {
  margin-right: -24px;
}

.navbar-right > li > a {
  align-items: center;
  padding-left: 8px;
  padding-right: 9px;
  display: flex;
}

.navbar-right > li > a > span {
  padding-left: 8px;
  padding-right: 4px;
}

@media (width <= 1199px) {
  .navbar-nav svg {
    display: none;
  }

  .navbar-nav svg.profile-icon {
    display: block;
  }
}

@media (width <= 991px) {
  .navbar-header {
    float: none;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-right {
    margin-right: -15px;
  }

  .navbar-collapse {
    z-index: 100;
    background-color: #fff;
    border-top: 1px solid #0000;
    border-bottom: 1px solid #e7e7e7;
    width: 100%;
    max-height: none;
    position: absolute;
    top: 48px;
    left: 0;
    box-shadow: inset 0 1px #ffffff1a;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-collapse.collapse.in {
    display: block !important;
  }

  .navbar-nav {
    max-width: 750px;
    margin: 0 auto;
    float: none !important;
  }

  .navbar-nav > li {
    float: none;
  }

  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (width <= 768px) {
  .navbar-nav {
    margin: 7.5px 0;
  }

  .container > .navbar-collapse {
    margin: 0;
  }

  .nav > li > a {
    padding: 10px 0;
  }
}

.horizontal-breadcrumb > li {
  display: inline;
}

.horizontal-breadcrumb > li.next, .horizontal-breadcrumb > li.disabled {
  color: var(--color-primary-500);
}

.horizontal-breadcrumb > li.current {
  font-weight: 700;
}

.horizontal-breadcrumb > li.previous {
  color: var(--color-primary-300);
  cursor: pointer;
}

.horizontal-breadcrumb > li:not(:last-of-type):after {
  content: "/";
  color: var(--color-primary-500);
  margin: 0 10px;
}

@media print {
  html, body {
    height: 99%;
  }

  body {
    padding-top: 0;
  }
}

.media-print {
  background-color: #fff;
  width: 8.5in;
  margin: auto;
  padding: .5in;
  position: relative;
}

.media-print h1, .media-print h2, .media-print h3 {
  font-weight: 200;
}

.media-print h3 {
  border-width: 1px;
  border-color: var(--color-primary-300);
  color: var(--color-primary-300);
}

.media-print .invoice-number {
  color: var(--color-primary-500);
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: 12px;
}

.media-print .invoice-charge {
  page-break-inside: avoid;
  margin-top: 5px;
  margin-bottom: 10px;
}

.media-print .total {
  text-align: right;
  font-weight: normal;
}

.media-print .invoice-container {
  font-weight: 200;
}

.media-print .invoice-container .highlight {
  border-width: 1px;
  border-color: var(--color-primary-300);
  color: var(--color-primary-300);
}

.media-print .invoice-container .invoice-watermark {
  color: var(--color-primary-500);
  z-index: 0;
  opacity: .1;
  text-transform: uppercase;
  font-size: 225px;
  position: absolute;
  top: 6in;
  left: 1.5in;
  transform: rotate(-15deg);
}

.media-print .invoice-container .invoice-body {
  min-height: 10in;
  margin-bottom: -2in;
  padding-bottom: 2in;
}

.media-print .invoice-container .invoice-footer {
  text-align: center;
  page-break-inside: avoid;
}

.media-print .invoice-container .invoice-footer .subtitle {
  font-size: 20px;
}

.media-print .invoice-container .invoice-footer .bubble {
  border-width: 1px;
  border-color: var(--color-primary-300);
  color: var(--color-primary-300);
  background-color: #f8f8f8;
  border-radius: 10px;
  font-size: 40px;
}

.progress {
  margin: 5px 0;
}

.form-signup .form-signin-heading, .form-signin .form-signin-heading, .form-signup .checkbox, .form-signin .checkbox {
  margin-bottom: 10px;
}

.form-signup .checkbox, .form-signin .checkbox {
  font-weight: normal;
}

.form-signin {
  padding: 15px;
}

.form-signup, .form-signin {
  max-width: 330px;
  margin: 0 auto;
}

.form-signup .form-group, .form-signin .form-group {
  margin-bottom: 10px;
}

.form-signup .form-group.form-group-compact input, .form-signup .form-group.form-group-compact button, .form-signin .form-group.form-group-compact input, .form-signin .form-group.form-group-compact button {
  border-radius: 0;
}

.form-signup .form-group.form-group-compact input:first-child, .form-signin .form-group.form-group-compact input:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.form-signup .form-group.form-group-compact button:last-child, .form-signup .form-group.form-group-compact input:last-child, .form-signin .form-group.form-group-compact button:last-child, .form-signin .form-group.form-group-compact input:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.form-signup .has-feedback .form-control-feedback, .form-signin .has-feedback .form-control-feedback {
  top: 5px;
  right: 5px;
}

.form-signup .form-control, .form-signin .form-control {
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
  position: relative;
}

.well.signin {
  background-color: #fff;
  max-width: 400px;
  margin: auto;
}

.StripeElement.form-control {
  height: 42px;
  padding: 12px;
}

.StripeElement--invalid {
  border-color: var(--color-danger-300);
}

.StripeElement--focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #66afe999;
}

.stripe-card > .StripeElement {
  height: auto;
  min-height: 12rem;
}

@media screen and (width >= 768px) {
  .stripe-card > .StripeElement {
    min-height: 8rem;
  }
}

.spinner-modal {
  justify-content: center;
  align-items: center;
  height: 13rem;
  display: flex;
}

.hide-group-names .ui-select-choices-group-label {
  display: none;
}

.hide-group-names .divider {
  margin: 5px 0;
}

.ui-select-bootstrap > .ui-select-match > .ui-select-toggle {
  text-align: left;
  padding-left: 10px;
  overflow: hidden;
}

.ui-select-choices-row-inner {
  white-space: normal !important;
}

.picker .ui-select-container {
  width: 100%;
}

.picker .ui-select-placeholder {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 75%;
  color: inherit;
  text-align: center;
  display: inline-block;
  overflow: hidden;
}

.picker--active .ui-select-toggle {
  color: #fff;
  background-color: var(--color-primary-300);
  border-color: #2e6da4;
}

.picker.ui-select-bootstrap > .ui-select-choices {
  width: auto;
  min-width: 100%;
}

.picker.ui-select-bootstrap > .ui-select-choices .ui-select-choices-group-label {
  display: none;
}

.picker.ui-select-bootstrap > .ui-select-choices .divider {
  margin: 5px 0;
}

.dropdown > .dropdown-toggle {
  text-align: left;
}

.dropdown > .dropdown-toggle:active {
  box-shadow: none;
  outline: none;
}

.dropdown > .ui-select-toggle {
  text-align: left;
}

.hide-dropdown-group-names.ui-select-bootstrap > .ui-select-choices .ui-select-choices-group-label {
  display: none;
}

.hide-dropdown-group-names.ui-select-bootstrap > .ui-select-choices .divider {
  margin: 5px 0;
}

.ui-select-match-text {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.form-control.ui-select-search {
  width: 100% !important;
}

.clear-search {
  color: var(--color-primary-500);
  cursor: pointer;
  top: 10px;
  right: 20px;
  position: absolute !important;
}

.loading-search {
  color: var(--color-primary-500);
  top: 10px;
  right: 8px;
  position: absolute !important;
}

.lens-search {
  color: var(--color-primary-500);
  position: absolute;
  top: 10px;
  left: 8px;
}

.email-preview {
  border-width: 1px;
  border-color: var(--color-primary-150);
  background-color: #fafafa;
  border-radius: 10px;
  padding: 15px;
}

.email-preview hr {
  color: var(--color-primary-150);
  margin-top: 10px;
}

.email-preview label {
  padding-right: 5px;
}

input[type="file"].file-without-file-name {
  color: #0000;
}

.subtotal {
  padding-left: 10px;
}

.numeric {
  text-align: right;
}

.reports .date {
  min-width: 100px;
}

.report-item.pending {
  border: 2px dashed var(--bs-info);
}

.patients .search {
  transition: width .2s ease-in-out, margin .2s ease-in-out;
}

.patients .patient {
  opacity: 1;
  transition: opacity .25s ease-in-out;
}

.chevron-right li:before {
  content: "";
}

.full-size .filter {
  width: 50%;
}

.full-size .filter-12 {
  width: 100%;
}

.full-size .company, .full-size .practitioner, .full-size .plan {
  width: 50%;
}

.full-size .form-group {
  height: 35px;
}

@media (width <= 768px) {
  .filter .form-group {
    height: 35px;
  }
}

.no-value {
  color: var(--color-primary-500);
  font-style: italic;
}

.no-value:after {
  content: "None";
}

.content-disabled {
  opacity: .4;
}

ul.no-bullets {
  list-style: none;
}

.text-capitalized {
  text-transform: capitalize;
}

.cascading-pricing-tier {
  justify-content: flex-start;
  display: flex;
}

:not(:last-child).cascading-pricing-tier:after {
  content: "+";
  color: gray;
  margin-left: 20px;
  margin-right: 20px;
  font-size: xx-large;
}

.embedded .vertical-wrapper.with-full-navigation {
  max-width: 100%;
  margin-top: 0;
  margin-left: 0;
}

.embedded .container.content {
  background-color: #fff;
  height: 100%;
  padding: 0;
}

.embedded .panel {
  box-shadow: none;
  border: none;
  border-radius: 0;
  margin: 0;
  padding-bottom: 55px;
  position: relative;
}

.embedded .panel .panel-heading {
  display: none;
}

.embedded .panel .panel-footer {
  background-color: #fff;
}

.embedded .panel .panel-footer .link.btn.btn-link {
  display: none;
}

.embedded .panel .panel-footer .text-center {
  text-align: right;
}

.embedded .panel .panel-footer .list-group {
  margin-bottom: 0;
}

.embedded .panel:last-of-type > .panel-footer {
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.embedded .container {
  margin: 0;
}

.embedded .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
}

.embedded .modal-dialog .modal-content {
  background-clip: initial;
  border: none;
  border-radius: 0;
  height: 100%;
}

.embedded .modal-dialog .modal-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.embedded a.link {
  color: currentColor;
  pointer-events: none;
}

.pac-container {
  z-index: 1040;
}

.notification-email-layout {
  font-family: Gotham, sans-serif;
}

.notification-email-layout h1, .notification-email-layout h2, .notification-email-layout h3, .notification-email-layout h4, .notification-email-layout h5, .notification-email-layout h6 {
  color: var(--color-primary-300);
  margin: 10px 0;
  line-height: 1.1;
}

.notification-email-layout h1 {
  font-size: 22px;
}

.notification-email-layout h2 {
  font-size: 18px;
}

.notification-email-layout h3 {
  font-size: 16px;
}

.notification-email-layout h4, .notification-email-layout h5, .notification-email-layout h6 {
  font-size: 14px;
}

.notification-email-layout p {
  color: #303539;
  margin: 0 0 25px;
  font-size: 14px;
  line-height: 1.4;
}

.notification-email-layout a {
  color: #00bdf0;
}

.notification-email-layout ul li, .notification-email-layout ol li {
  font-size: 14px;
  line-height: 1.5;
}

#ng-app {
  height: 100%;
}

.header, .hero, .redesign-hide, #header-admin {
  display: none;
}
/*# sourceMappingURL=provider-portal.23175c95.css.map */
